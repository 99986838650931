export const GrowthV1PlanID = 'growth-1';
export const GrowthV2PlanID = 'growth-2';
export const GrowthV1AnnualPlanID = 'growth-1-annual';
export const GrowthV2AnnualPlanID = 'growth-2-annual';
export const GrowthV1FixedPlanID = 'growth-1-fixed';
export const GrowthPlanIDs = [
  GrowthV1PlanID,
  GrowthV2PlanID,
  GrowthV1AnnualPlanID,
  GrowthV2AnnualPlanID,
  GrowthV1FixedPlanID,
];
export const GrowthIncrementalAdminsPlanIDs = [
  'growth-1-admins-incremental',
  'growth-2-admins-incremental',
  'growth-1-annual-admins-incremental',
  'growth-2-annual-admins-incremental',
];

export const GrowthPlanID = GrowthV2PlanID;
export const GrowthAnnualPlanID = GrowthV2AnnualPlanID;

export default function isGrowth(planID: string | null) {
  if (!planID) {
    return false;
  }

  return GrowthPlanIDs.includes(planID);
}
