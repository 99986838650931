import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadBoard } from 'common/actions/boards';
import { reloadCompany } from 'common/actions/company';
import { invalidateDashboardActivity } from 'common/actions/dashboardActivity';
import { deletePostDraft, savePostDraft } from 'common/actions/postDraft';
import { invalidatePostQueries, loadQuery } from 'common/actions/postQueries';
import { reloadPost } from 'common/actions/posts';
import { reloadPostActivity } from 'common/actions/postsActivity';
import { invalidateUserQueries } from 'common/actions/userQueries';
import AJAX from 'common/AJAX';
import CompanySelector from 'common/chrome/components/CompanySelector';
import LogoutButton from 'common/chrome/components/LogoutButton';
import CompanyUserSearch from 'common/company/CompanyUserSearch';
import { LoadStatus } from 'common/constants/files';
import Via from 'common/constants/via';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { IsBrowserExtensionContext } from 'common/containers/IsBrowserExtensionContainer';
import { CloseModalContext } from 'common/containers/ModalContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import connect from 'common/core/connect';
import Dropdown from 'common/Dropdown';
import FileRenderer from 'common/file/FileRenderer';
import getAcceptedMimeTypes from 'common/file/utils/getAcceptedMimeTypes';
import getValidFileURLs from 'common/file/utils/getValidFileURLs';
import uploadFile, { numberOfFilesLimit } from 'common/file/utils/uploadFile';
import Form from 'common/Form';
import FormField from 'common/FormField';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import TextInput from 'common/inputs/TextInput';
import UploadFileButton from 'common/inputs/UploadFileButton';
import { KeyNames } from 'common/KeyCodes';
import CustomPostFieldInput, { getErrorMessages } from 'common/post/CustomPostFieldInput';
import PostCategoryMenu from 'common/post/PostCategoryMenu';
import AdminCreatePostHeader from 'common/subdomain/admin/AdminCreatePostModal/AdminCreatePostHeader';
import AdminCreatePostSuggestions from 'common/subdomain/admin/AdminCreatePostModal/AdminCreatePostSuggestions';
import Tappable from 'common/Tappable';
import ButtonV2 from 'common/ui/ButtonV2';
import UserLockup from 'common/user/UserLockup';
import delayer from 'common/util/delayer';
import mapify from 'common/util/mapify';
import nbspLastSpace from 'common/util/nbspLastSpace';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import stringSort from 'common/util/stringSort';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/subdomain/admin/_AdminCreatePostModal.scss';

class AdminCreatePostModal extends Component {
  static propTypes = {
    board: PropTypes.object,
    boards: PropTypes.arrayOf(PropTypes.object),
    company: PropTypes.object,
    externalLink: PropTypes.string,
    isBrowserExtension: PropTypes.bool,
    onCompanySelected: PropTypes.func,
    onPostCreated: PropTypes.func,
    onVoteAdded: PropTypes.func,
    onUnmount: PropTypes.func,
    closeModal: PropTypes.func,
    location: PropTypes.object,
    router: PropTypes.object,
    showToast: PropTypes.func,
    suggestedUser: PropTypes.string,
    viewer: PropTypes.object,
    allowCreateAnother: PropTypes.bool,
  };

  state = {
    comment: this.props.postDraft.internalComment ?? null,
    customFieldValuesMap: null,
    shouldCreateNewUser: null,
    details: this.props.postDraft.details || '',
    error: null,
    erroredFields: [],
    fetchingSuggestions: false,
    files: this.props.postDraft.files || [],
    lastSelectedUser: this.props.viewer,
    selectedBoard: this.props.postDraft.selectedBoard || this.props.board || this.props.boards[0],
    selectedCategory: this.props.postDraft.selectedCategory || null,
    selectedPost: null,
    selectedUser: this.props.postDraft.author ?? this.props.viewer,
    submitting: false,
    submittingWithNew: false,
    title: this.props.postDraft.title || '',
    allowCreateAnother: false,
  };

  constructor(props, context) {
    super(props, context);

    this.detailsInputRef = React.createRef();
    this.searchRef = React.createRef();
    this.titleInputRef = React.createRef();
    this.customFieldInputRefs = React.createRef();
    this.internalCommentRef = React.createRef();

    this.customFieldInputRefs.current = [];
  }

  componentDidMount() {
    const { board, boards, postDraft } = this.props;
    this._formDelayer = new delayer(this.onFormChangeAfterDelay, 400);
    this._formDelayer.callAfterDelay();

    document.addEventListener('keydown', this.handleEscape);

    const selectedBoard = postDraft.selectedBoard || board || boards[0];
    const doesBoardExist = !!boards.find((board) => selectedBoard._id === board._id);
    if (!doesBoardExist) {
      this.setState({
        selectedBoard: boards[0],
        selectedCategory: null,
      });
      return;
    }
  }

  componentWillUnmount() {
    this._formDelayer.cancel();
    this.props.onUnmount?.();
    document.removeEventListener('keydown', this.handleEscape);
  }

  handleEscape = (e) => {
    if (e.key === KeyNames.Escape) {
      this.props.closeModal();
      e.preventDefault();
    }
  };

  addVote = async (createAnother) => {
    const { isBrowserExtension, location, onVoteAdded, router, showToast } = this.props;
    const { comment, shouldCreateNewUser, selectedPost, selectedUser } = this.state;

    this.setState({
      error: null,
      erroredFields: [],
      submitting: true,
      submittingWithNew: createAnother,
    });

    const doOnBehalfOfUser = this.checkActionIsOnBehalf();
    const selectedUserID = shouldCreateNewUser
      ? (await this.createCompanyUser())?.userID
      : selectedUser?._id;

    if (doOnBehalfOfUser && !selectedUserID) {
      this.setState({
        error: 'Something went wrong, please try again later.',
        submitting: false,
        submittingWithNew: false,
      });
      return;
    }

    const requestEndpoint = doOnBehalfOfUser ? '/api/posts/voteAs' : '/api/posts/vote';
    let response;

    try {
      const responseJSON = await AJAX.post(requestEndpoint, {
        ...(doOnBehalfOfUser && { userID: selectedUserID }),
        ...(doOnBehalfOfUser && comment && { comment }),
        ...(isBrowserExtension && {
          via: Via.browserExtension,
          externalLink: this.props.externalLink,
        }),
        postID: selectedPost._id,
        score: 1,
      });
      response = parseAPIResponse(responseJSON, {
        isSuccessful: isDefaultSuccessResponse,
      });
    } catch (error) {
      response = { error: { message: 'Something went wrong, please try again later.' } };
    }

    const { error } = response;

    if (error) {
      this.setState({
        submitting: false,
        submittingWithNew: false,
        error: error.message,
      });
      return;
    }

    if (isBrowserExtension) {
      onVoteAdded?.(selectedPost);
      return;
    }

    await Promise.all([
      this.props.reloadPost(selectedPost),
      this.props.reloadPostActivity(selectedPost),
    ]);

    this.props.invalidateUsers();
    this.props.deletePostDraft();

    if (createAnother) {
      showToast('Vote added', ToastTypes.success);
      this.clearForm();
    } else {
      this.props.closeModal();
      if (!onVoteAdded) {
        router.replace({
          pathname: `/admin/feedback/${selectedPost.board.urlName}/p/${selectedPost.urlName}`,
          query: location.query,
        });
      }
    }

    if (onVoteAdded) {
      onVoteAdded(selectedPost);
    }
  };

  areFilesUploading = () => {
    return this.state.files.some((file) => file.uploadStatus !== LoadStatus.loaded);
  };

  createPost = async (createAnother) => {
    const { isBrowserExtension, location, onPostCreated, router, showToast } = this.props;
    const {
      customFieldValuesMap,
      shouldCreateNewUser,
      selectedBoard,
      selectedCategory,
      selectedUser,
    } = this.state;
    const details = this.detailsInputRef.current?.getValue().trim();
    const title = this.titleInputRef.current?.getValue().trim();
    const validFileURLs = getValidFileURLs(this.state.files);
    const imageURLs = JSON.stringify(validFileURLs.imageURLs);
    const fileURLs = JSON.stringify(validFileURLs.nonImageFileURLs);

    if (!selectedBoard || !validateInput.id(selectedBoard._id)) {
      this.setState({
        error: 'Something went wrong, please try again later.',
      });
      return;
    } else if (!validateInput.postTitle(title)) {
      this.setState({
        error: 'Please enter a title between 1 and 400 characters.',
        erroredFields: ['_title'],
      });
      return;
    } else if (!validateInput.postDetails(details)) {
      this.setState({
        error: 'Please enter details between 0 and 5000 characters.',
      });
      return;
    } else if (!validateInput.postImageURLs(imageURLs)) {
      this.setState({
        error: 'Something went wrong, please try again later.',
      });
      return;
    } else if (!validateInput.publicNonImageFileURLs(fileURLs)) {
      this.setState({
        error: 'Files were not uploaded correctly.',
      });
      return;
    } else if (selectedBoard.settings.detailsRequired && !details) {
      this.setState({
        error: 'Please enter details',
      });
      return;
    }

    const hasCustomFields = selectedBoard.boardFields.length;
    if (hasCustomFields) {
      const { erroredFields, errorMessages } = getErrorMessages(
        customFieldValuesMap,
        selectedBoard.boardFields
      );
      if (errorMessages.length) {
        const error = (
          <>
            <p>There are some issues with your form:</p>
            <ul>
              {errorMessages.map((message) => (
                <li key={message}>{nbspLastSpace(message)}</li>
              ))}
            </ul>
          </>
        );

        this.setState({ error, erroredFields });
        return;
      }
    }

    this.setState({
      error: null,
      erroredFields: [],
      submitting: true,
      submittingWithNew: createAnother,
    });

    const doOnBehalfOfUser = this.checkActionIsOnBehalf();
    const selectedUserID = shouldCreateNewUser
      ? (await this.createCompanyUser())?.userID
      : selectedUser?._id;

    if (doOnBehalfOfUser && !selectedUserID) {
      this.setState({
        error: 'Something went wrong, please try again later.',
        submitting: false,
        submittingWithNew: false,
      });
      return;
    }

    let response;
    try {
      const responseJSON = await AJAX.post('/api/posts/create', {
        boardID: selectedBoard._id,
        customFieldValuesMap: hasCustomFields ? customFieldValuesMap || {} : null, // avoid blocking non-allowlisted companies
        details,
        fileURLs,
        imageURLs,
        title,
        ...(selectedCategory && {
          categoryID: selectedCategory._id,
        }),
        ...(doOnBehalfOfUser && { authorID: selectedUserID }),
        ...(isBrowserExtension && {
          externalLink: this.props.externalLink,
          via: Via.browserExtension,
        }),
      });
      response = parseAPIResponse(responseJSON, {
        isSuccessful: (parsedResponse) => parsedResponse.post,
        errors: {
          'slow down':
            'You are trying to create posts too fast. Please wait a few minutes before trying again.',
          spam: `Our system identifies parts of this post as spam. Please, try with a different content.`,
        },
      });
    } catch (error) {
      response = { error: { message: 'Something went wrong, please try again later.' } };
    }

    const { error, parsedResponse } = response;

    if (error) {
      this.setState({
        submitting: false,
        submittingWithNew: false,
        error: error.message,
      });
      return;
    }

    const { post } = parsedResponse;
    if (isBrowserExtension) {
      onPostCreated?.(post);
      return;
    }

    await this.props.reloadData(post);
    this.props.invalidateUsers();
    this.props.deletePostDraft();

    if (createAnother) {
      showToast('Post created', ToastTypes.success);
      this.clearForm();
    } else {
      this.props.closeModal();
      if (!onPostCreated) {
        router.replace({
          pathname: `/admin/feedback/${post.board.urlName}/p/${post.urlName}`,
          query: location.query,
        });
        return;
      }
    }

    if (onPostCreated) {
      onPostCreated(post);
    }
  };

  clearForm = () => {
    // reset state
    this.setState({
      submitting: false,
      submittingWithNew: false,
      imageURLs: [],
      fileURLs: [],
      files: [],
      customFieldValuesMap: null,
      comment: null,
      selectedPost: null,
    });

    // clear any ref values
    this.titleInputRef.current?.setValue('');
    this.detailsInputRef.current?.setValue('');
    this.internalCommentRef.current?.setValue('');
    this.customFieldInputRefs.current?.forEach((inputRef) => {
      inputRef?.setValue('');
    });

    this._formDelayer.callAfterDelay();
  };

  createCompanyUser = async () => {
    const { email, name } = this.searchRef.current?.wrappedInstance?.getFormValues() ?? {};

    if (!name || !validateInput.userName(name)) {
      this.setState({
        error: 'Please enter a valid name for user (2-50)',
      });
      return;
    }

    if (email && !validateInput.email(email)) {
      this.setState({
        error: 'Please enter a valid email address for user',
      });
      return;
    }

    this.setState({ shouldCreateNewUser: false });

    try {
      const response = await AJAX.post('/api/company/createUser', {
        name,
        ...(email && { email }),
      });
      return JSON.parse(response);
    } catch (error) {
      return null;
    }
  };

  checkActionIsOnBehalf = () => {
    const { selectedUser, shouldCreateNewUser } = this.state;
    const { viewer } = this.props;
    return shouldCreateNewUser || (!!selectedUser && selectedUser._id !== viewer._id);
  };

  setSelectedUser(selectedUser) {
    this.setState((state) => ({
      ...state,
      lastSelectedUser: state.selectedUser,
      selectedUser,
    }));
  }

  onBoardSelected = (boardID) => {
    const board = this.props.boards.find((board) => {
      return board._id === boardID;
    });
    this.setState({
      selectedBoard: board,
      selectedCategory: null,
    });

    this._formDelayer.callAfterDelay();
  };

  onCategorySelected = (categoryID) => {
    const { selectedBoard } = this.state;
    if (!selectedBoard) {
      return;
    }

    const { categories } = selectedBoard;
    const selectedCategory =
      categoryID === 'uncategorized'
        ? null
        : categories.find((category) => {
            return category._id === categoryID;
          });
    this.setState({
      selectedCategory,
    });

    this._formDelayer.callAfterDelay();
  };

  onCommentChange = (e) => {
    this.setState({ comment: e.target.value });
  };

  onCompanyUserSearchBlur = () => {
    // Note: If user does not select any items from search
    // We want to revert back to lastSelectedUser but we have to wait for state updates before reverting back
    setTimeout(() => {
      const { viewer } = this.props;

      this.setState((state) => {
        const { selectedUser, shouldCreateNewUser } = state;

        if (selectedUser || shouldCreateNewUser) {
          return state;
        }

        return { ...state, lastSelectedUser: state.selectedUser, selectedUser: viewer };
      });
    }, 300);
  };

  onCustomFieldChange = (value, boardField) => {
    this.setState((state) => ({
      customFieldValuesMap: {
        ...state.customFieldValuesMap,
        [boardField.customPostFieldID]: value,
      },
    }));
  };

  onDetailsChange = () => {
    this._formDelayer.callAfterDelay();
  };

  onFormChangeAfterDelay = () => {
    this.onFormChangeSaveDraft();

    const { selectedBoard } = this.state;
    if (!selectedBoard) {
      return;
    }

    const { loadQuery } = this.props;
    const details = this.detailsInputRef.current?.getValue();
    const title = this.titleInputRef.current?.getValue();
    const textSearch = ((details || '') + ' ' + (title || '')).trim();

    this.setState({ selectedPost: null });

    if (!textSearch) {
      this.setState({
        details,
        fetchingSuggestions: false,
        title,
      });
      return;
    }

    const timeout = setTimeout(() => {
      this.setState({
        fetchingSuggestions: true,
      });
    }, 1000);

    loadQuery({
      board: selectedBoard,
      pages: 1,
      textSearch,
    }).then(() => {
      if (timeout) {
        clearTimeout(timeout);
      }
      this.setState({
        details,
        fetchingSuggestions: false,
        title,
      });
    });
  };

  onFormChangeSaveDraft = () => {
    const { files, selectedBoard, selectedCategory } = this.state;
    const details = this.detailsInputRef.current?.getValue().trim() ?? this.state.details;
    const title = this.titleInputRef.current?.getValue().trim() ?? this.state.title;
    const { imageURLs, nonImageFileURLs } = getValidFileURLs(files);

    // Note: We delete post draft if there is no context
    // Otherwise state.board is overridden by postDraft.board
    if (!details && !title && !imageURLs.length && !nonImageFileURLs.length) {
      this.props.deletePostDraft();
      return;
    }

    this.props.savePostDraft({
      details,
      files,
      selectedBoard,
      selectedCategory,
      title,
    });
  };

  onFileStart = () => {
    const { files } = this.state;
    const { showToast } = this.props;

    if (files.length >= numberOfFilesLimit) {
      showToast('You have reached the file limit.', ToastTypes.error);

      return false;
    }

    return true;
  };

  onFile = async (file) => {
    const { files } = this.state;
    const { viewer, showToast } = this.props;

    if (files.length >= numberOfFilesLimit) {
      showToast('You have reached the file limit.', ToastTypes.error);

      return;
    }

    await uploadFile({
      file,
      viewer,
      onFileError: this.onFileError,
      onFileUploading: this.onFileUploading,
      onFileUploaded: this.onFileUploaded,
    });
  };

  onFileError = (file, error) => {
    this.setState(
      (state) => ({
        error,
        files: state.files.filter((f) => f.uniqueID !== file.uniqueID),
      }),
      this.onFormChangeSaveDraft
    );
  };

  onFileUploading = (file) => {
    this.setState((state) => ({
      files: [...state.files, file],
    }));
  };

  onFileUploaded = (file) => {
    this.setState(
      (state) => ({
        files: state.files.map((f) => (f.uniqueID === file.uniqueID ? file : f)),
      }),
      this.onFormChangeSaveDraft
    );
  };

  onFileRemoved = (file) => {
    this.setState(
      (state) => ({
        files: state.files.filter((f) => f.uniqueID !== file.uniqueID),
      }),
      this.onFormChangeSaveDraft
    );
  };

  onNewUserSelected = () => {
    this.setState({ shouldCreateNewUser: true });
    this.props.onNewUserSelected?.();
  };

  onSuggestionSelected = (event, post) => {
    const { selectedPost } = this.state;

    if (selectedPost?._id === post?._id) {
      this.setState({ selectedPost: null });
      return;
    }

    this.setState({
      selectedPost: {
        ...post,
        boardURLName: post.board.urlName,
        postURLName: post.urlName,
      },
    });
  };

  onTitleChange = () => {
    this._formDelayer.callAfterDelay();
  };

  onUserSelected = (selectedUser) => {
    this.setSelectedUser(selectedUser);
    this.props.onUserSelected?.();
  };

  removeSelectedUser = () => {
    this.setState({ selectedUser: null });
  };

  getRequiredFieldLabel = (label) => {
    return (
      <span aria-label={`${label} (required)`}>
        {label}
        <span className="requiredFieldAsterisk" aria-hidden>
          *
        </span>
      </span>
    );
  };

  renderBoardSelector() {
    const { boards } = this.props;

    const { selectedBoard } = this.state;
    return (
      <FormField label={this.getRequiredFieldLabel('Board')}>
        <ControlledDropdown
          className="boardSelector"
          selectedName={selectedBoard ? selectedBoard._id : null}
          onChange={this.onBoardSelected}
          options={boards.map((board) => ({
            name: board._id,
            render: board.name,
          }))}
        />
      </FormField>
    );
  }

  renderCategorySelector() {
    const { selectedBoard, selectedCategory } = this.state;
    if (!selectedBoard?.categories || !selectedBoard.categories.length) {
      return null;
    }

    if (selectedBoard.categories.length < 3) {
      const defaultSelectedName = selectedCategory ? selectedCategory._id : null;
      const categoriesIndexed = selectedBoard.categories.every(
        (category) => category.index !== null
      );
      const sortFunction = categoriesIndexed ? (a, b) => a.index - b.index : stringSort('name');
      const sortedCategories = [...selectedBoard.categories].sort(sortFunction);
      const parentCategories = sortedCategories.filter((category) => !category.parentID);
      const options = [];

      parentCategories.forEach((parent) => {
        const pushCategories = [
          parent,
          ...sortedCategories.filter((category) => category.parentID === parent._id),
        ];
        options.push(
          ...pushCategories.map((category) => ({
            indent: !!category.parentID,
            name: category._id,
            render: category.name,
          }))
        );
      });

      options.push({
        name: 'uncategorized',
        render: 'Uncategorized',
      });

      return (
        <FormField label="Category">
          <Dropdown
            className="categoryDropdown"
            defaultSelectedName={defaultSelectedName}
            onChange={this.onCategorySelected}
            options={options}
            {...(defaultSelectedName ? {} : { placeholder: 'Select Category' })}
          />
        </FormField>
      );
    }

    const placeholder = selectedCategory?.name ?? 'Select Category';
    return (
      <FormField label="Category">
        <PostCategoryMenu
          board={selectedBoard}
          onCategorySelected={(category) => this.onCategorySelected(category._id)}>
          <Dropdown
            className={classnames('categoryDropdown', {
              placeholderSelected: !!selectedCategory?.name,
            })}
            options={[]}
            placeholder={placeholder}
          />
        </PostCategoryMenu>
      </FormField>
    );
  }

  renderDetailsInput() {
    const { selectedBoard } = this.state;
    if (!selectedBoard) {
      return null;
    }

    const { strings, settings } = selectedBoard;
    const label = settings.detailsRequired
      ? this.getRequiredFieldLabel(strings.detailsField)
      : strings.detailsField;
    return (
      <FormField label={label}>
        <AutoResizeTextarea
          className="detailsInput"
          defaultValue={this.state.details}
          maxRows={10}
          minRows={3}
          onChange={this.onDetailsChange}
          placeholder={strings.detailsPlaceholder}
          ref={this.detailsInputRef}
        />
      </FormField>
    );
  }

  renderErrorMessage() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return (
      <div className="error" role="alert">
        {error}
      </div>
    );
  }

  renderFileButton() {
    return (
      <UploadFileButton
        acceptedMimeTypes={getAcceptedMimeTypes(this.props.company)}
        defaultStyle={false}
        onFileError={this.onFileError}
        onFileStart={this.onFileStart}
        onFileUploading={this.onFileUploading}
        onFileUploaded={this.onFileUploaded}
      />
    );
  }

  renderPostDetails = () => {
    const { selectedPost } = this.state;
    const { postDraft } = this.props;
    const newPostFields = (
      <>
        {this.renderCategorySelector()}
        {this.renderTitleInput()}
        {this.renderDetailsInput()}
        {this.renderCustomFields()}
        <FileRenderer
          className="adminPostFormFileRenderer"
          allowRemove={true}
          files={this.state.files}
          onFileRemoved={this.onFileRemoved}
        />
        {this.renderFileButton()}
      </>
    );
    const showCommentInput = selectedPost && this.checkActionIsOnBehalf();
    const selectedPostFields = (
      <>
        {showCommentInput && (
          <FormField label="Internal comment">
            <AutoResizeTextarea
              className="commentInput"
              defaultValue={postDraft.internalComment}
              maxRows={10}
              minRows={3}
              onChange={this.onCommentChange}
              placeholder="Any additional details..."
              ref={this.internalCommentRef}
            />
          </FormField>
        )}
      </>
    );

    return (
      <div className="formContainer">
        {this.renderAuthorSelector()}
        {this.renderBoardSelector()}
        {selectedPost ? selectedPostFields : newPostFields}
      </div>
    );
  };

  renderMainSection() {
    return (
      <div className="mainSection">
        {this.renderPostDetails()}
        {this.renderSuggestions()}
      </div>
    );
  }

  renderAuthorSelector() {
    const { isBrowserExtension, suggestedUser } = this.props;
    const { selectedPost } = this.state;

    const secondaryLabel = selectedPost ? (
      <>Vote and comment as yourself or on behalf of another&nbsp;user</>
    ) : (
      'Post as yourself or on behalf of another user'
    );

    return (
      <FormField
        label={this.getRequiredFieldLabel('Author')}
        secondaryLabel={<p className="formFieldSecondaryLabel">{secondaryLabel}</p>}>
        <>
          <div className="search">
            {this.state.selectedUser ? (
              this.renderSelectedUser()
            ) : (
              <CompanyUserSearch
                autoFocus={true}
                disabled={this.state.submitting}
                onBlur={this.onCompanyUserSearchBlur}
                onNewUserSelected={this.onNewUserSelected}
                onUserSelected={this.onUserSelected}
                ref={this.searchRef}
                suggestedUser={isBrowserExtension ? suggestedUser : null}
                userPlaceholder="User's name"
              />
            )}
          </div>
        </>
      </FormField>
    );
  }

  renderSelectedUser() {
    const { selectedUser, submitting } = this.state;

    return (
      <div className="selectedUserContent">
        <UserLockup showCompanyNames={true} showProfile={false} user={selectedUser} />
        {!submitting ? (
          <div className="removeButton">
            <Tappable onTap={this.removeSelectedUser}>
              <div className="icon icon-x" />
            </Tappable>
          </div>
        ) : null}
      </div>
    );
  }

  renderSubmitSection() {
    const { selectedBoard, selectedPost, submitting, submittingWithNew } = this.state;
    const {
      company,
      isBrowserExtension,
      onCompanySelected,
      viewer,
      closeModal,
      allowCreateAnother,
    } = this.props;
    const { strings } = selectedBoard;
    const submitButtonCopy = selectedPost ? 'Vote' : strings.createCTA;
    const cancelButtonCopy = 'Cancel';
    const createAnotherCopy = selectedPost ? 'Vote and New' : `${strings.createCTA} and New`;

    return (
      <div className="submitSection">
        <div className="actionsSection">
          <div className="right">
            {!isBrowserExtension && (
              <>
                <ButtonV2
                  variant="outlined"
                  className="closeButton"
                  onClick={closeModal}
                  disabled={submitting}>
                  {cancelButtonCopy}
                </ButtonV2>
                {allowCreateAnother && (
                  <ButtonV2
                    onClick={() =>
                      this.state.selectedPost ? this.addVote(true) : this.createPost(true)
                    }
                    disabled={this.areFilesUploading() || (submitting && !submittingWithNew)}
                    loading={submitting && submittingWithNew}
                    variant="outlined">
                    {createAnotherCopy}
                  </ButtonV2>
                )}
              </>
            )}
            <ButtonV2
              className="submitButton"
              type="submit"
              loading={submitting && !submittingWithNew}
              disabled={this.areFilesUploading() || (submitting && submittingWithNew)}>
              {submitButtonCopy}
            </ButtonV2>
            {isBrowserExtension && (
              <CompanySelector
                company={company}
                onCompanySelected={onCompanySelected}
                viewer={viewer}
              />
            )}
          </div>
          <div className="left">
            {isBrowserExtension && <LogoutButton />}
            {this.renderErrorMessage()}
          </div>
        </div>
      </div>
    );
  }

  renderSuggestions() {
    const { postQueries, posts } = this.props;
    const { details, fetchingSuggestions, selectedBoard, selectedPost, title } = this.state;

    return (
      <AdminCreatePostSuggestions
        postQueries={postQueries}
        posts={posts}
        details={details}
        fetchingSuggestions={fetchingSuggestions}
        selectedBoard={selectedBoard}
        selectedPost={selectedPost}
        title={title}
        onSuggestionSelected={this.onSuggestionSelected}
      />
    );
  }

  renderTitleInput() {
    const { selectedBoard, erroredFields } = this.state;
    if (!selectedBoard) {
      return null;
    }

    const { strings } = selectedBoard;
    return (
      <FormField label={this.getRequiredFieldLabel(strings.titleField)}>
        <TextInput
          autoFocus={true}
          className={classnames('titleInput', {
            errored: erroredFields.includes('_title'),
          })}
          defaultValue={this.state.title}
          onChange={this.onTitleChange}
          placeholder={strings.titlePlaceholder}
          ref={this.titleInputRef}
        />
      </FormField>
    );
  }

  renderCustomFields() {
    const { selectedBoard } = this.state;
    const { erroredFields } = this.state;

    const erroredFieldsMap = mapify(erroredFields, '_id');
    return selectedBoard.boardFields.map((boardField, index) => {
      const label = boardField.required
        ? this.getRequiredFieldLabel(boardField.label)
        : boardField.label;
      return (
        <FormField label={label} key={boardField._id}>
          <CustomPostFieldInput
            key={boardField._id}
            postField={boardField}
            className="customFieldInput"
            errored={!!erroredFieldsMap[boardField._id]}
            onChange={this.onCustomFieldChange}
            ref={(e) => (this.customFieldInputRefs.current[index] = e)}
          />
        </FormField>
      );
    });
  }

  render() {
    const { company, isBrowserExtension } = this.props;
    return (
      <Form
        acceptedFileTypes={getAcceptedMimeTypes(company)}
        addEventsToDocument={false}
        allowFileUpload={true}
        className={classnames('adminCreatePostModal', { browserExtension: isBrowserExtension })}
        disableSubmit={this.state.submitting || this.areFilesUploading()}
        onFile={this.onFile}
        onSubmit={() => {
          this.state.selectedPost ? this.addVote(false) : this.createPost(false);
        }}
        submitOnEnter={false}>
        <AdminCreatePostHeader isBrowserExtension={isBrowserExtension} />
        <div className="scrollableSections">{this.renderMainSection()}</div>
        {this.renderSubmitSection()}
      </Form>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      postDraft: state.postDraft,
      posts: state.posts,
      postQueries: state.postQueries,
    }),
    (dispatch) => ({
      deletePostDraft: () => {
        return dispatch(deletePostDraft());
      },
      invalidateUsers: () => {
        return dispatch(invalidateUserQueries());
      },
      loadQuery: (queryParams) => {
        return Promise.all([dispatch(loadQuery(queryParams))]);
      },
      reloadData: (post) => {
        return Promise.all([
          dispatch(invalidateDashboardActivity()),
          dispatch(invalidatePostQueries()),
          dispatch(reloadBoard(post.board.urlName)),
          dispatch(reloadCompany()),
          dispatch(reloadPost(post)),
          dispatch(reloadPostActivity(post)),
        ]);
      },
      reloadPost: (post) => {
        return dispatch(reloadPost(post));
      },
      reloadPostActivity: (post) => {
        return dispatch(reloadPostActivity(post));
      },
      savePostDraft: (postDraft) => {
        return dispatch(savePostDraft(postDraft));
      },
    })
  ),
  withContexts(
    {
      closeModal: CloseModalContext,
      company: CompanyContext,
      isBrowserExtension: IsBrowserExtensionContext,
      location: LocationContext,
      router: RouterContext,
      viewer: ViewerContext,
      showToast: ShowToastContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminCreatePostModal);
