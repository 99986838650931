import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import Colors from 'common/colors/constants';
import AutopilotCountsContainer from 'common/containers/AutopilotCountsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import IsAdminViewContext from 'common/contexts/IsAdminViewContext';
import asyncConnect from 'common/core/asyncConnect';
import Helmet from 'common/helmets/Helmet';
import Link from 'common/Link';
import CannyAnnouncementModal from 'common/modals/CannyAnnouncementModal';
import Spinner from 'common/Spinner';
import AdminNav from 'common/subdomain/admin/AdminNav';
import AdminPlanExpired from 'common/subdomain/admin/AdminPlanExpired';
import AdminV2Nav from 'common/subdomain/admin/v2/AdminV2Nav';
import AdminV2PostLimitNotice from 'common/subdomain/admin/v2/AdminV2Notices/AdminV2PostLimitNotice';
import AdminV2TrialNotice from 'common/subdomain/admin/v2/AdminV2Notices/AdminV2TrialNotice';
import { dayjs } from 'common/util/dayjsUtils';
import devURL from 'common/util/devURL';
import withContexts from 'common/util/withContexts';

import AccessContainer from './AccessContainer';
import BackgroundColorContainer from './BackgroundColorContainer';
import BingContainer from './BingContainer';
import BoardContainer, { asyncFetch as boardAsyncFetch } from './BoardContainer';
import BoardsContainer from './BoardsContainer';
import CannyContainer from './CannyContainer';
import CannylyticsContainer from './CannylyticsContainer';
import ChangelogEntryContainer from './ChangelogEntryContainer';
import ContentContainer from './ContentContainer';
import EventContainer from './EventContainer';
import HubSpotContainer from './HubSpotContainer';
import IntercomContainer from './IntercomContainer';
import LinkedInContainer from './LinkedInContainer';
import MixpanelContainer from './MixpanelContainer';
import ModalContainer from './ModalContainer';
import PostContainer from './PostContainer';
import PostLinkContainer from './PostLinkContainer';
import StripeContainer from './StripeContainer';
import TintColorContainer, { DefaultTintColor } from './TintColorContainer';
import ToastContainer from './ToastContainer';

import AutopilotAnnouncement from 'img/autopilot.svg';

import 'css/components/subdomain/admin/_AdminContainer.scss';

class AdminContainer extends Component {
  static propTypes = {
    boards: PropTypes.object,
    company: PropTypes.object,
    location: PropTypes.object,
    notifications: PropTypes.object,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  componentDidMount() {
    const {
      company,
      location: { pathname },
      params: { boardURLName, postURLName },
      router,
      viewer: { loggedOut },
    } = this.props;

    this.updateBodyOverflow();

    const { viewerIsMember } = company;
    if (viewerIsMember) {
      const { useCase } = company;
      if (!useCase.completed) {
        router.replace('/admin/onboarding/redirect');
        return;
      }

      return;
    }

    const homeRegex = /^\/admin\/boards\/?$/;
    const feedbackRegex = /^\/admin\/feedback\/[a-z0-9-]+\/p\/[a-z0-9-]+\/?$/;
    const boardRegex = /^\/admin\/board\/[a-z0-9-]+\/?$/;
    const postRegex = /^\/admin\/board\/[a-z0-9-]+\/p\/[a-z0-9-]+\/?$/;
    const createRegex = /^\/admin\/board\/[a-z0-9-]+\/create\/?$/;
    const votersRegex = /^\/admin\/board\/[a-z0-9-]+\/p\/[a-z0-9-]+\/voters\/?$/;

    if (homeRegex.test(pathname)) {
      router.replace('/');
    } else if (boardRegex.test(pathname)) {
      router.replace(`/${boardURLName}`);
    } else if (postRegex.test(pathname)) {
      router.replace(`/${boardURLName}/p/${postURLName}`);
    } else if (createRegex.test(pathname)) {
      router.replace(`/${boardURLName}/create`);
    } else if (votersRegex.test(pathname)) {
      router.replace(`/${boardURLName}/p/${postURLName}/voters`);
    } else if (feedbackRegex.test(pathname)) {
      router.replace(`/${boardURLName}/p/${postURLName}`);
    } else if (loggedOut) {
      const url = devURL('https://canny.io/login');
      window.location.assign(url);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.updateBodyOverflow();
    }
  }

  componentWillUnmount() {
    const html = document.body.parentElement;
    html.style.overflow = '';
    document.body.style.overflow = '';
  }

  getPostLink = (post) => {
    return `/admin/feedback/${post.board.urlName}/p/${post.urlName}?boards=${post.board.urlName}`;
  };

  updateBodyOverflow() {
    const html = document.body.parentElement;
    if (this.isFullScreenPath()) {
      // Prevents the body from having overflowing elements that cause a scrollbar
      // on pages with fixedHeight. This is to ensure an offscreen portal on these
      // pages doesn't cause an infinite body scroll.
      html.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    } else {
      html.style.overflow = '';
      document.body.style.overflow = '';
    }
  }

  renderContents() {
    const {
      company,
      location,
      params: { boardURLName, postURLName },
      viewer,
    } = this.props;
    const board = boardURLName ? this.props.boards.items[boardURLName] : null;
    const isLoading = viewer.loading || company.loading || (board && board.loading);
    const error = viewer.error || company.error || (board && board.error);

    const postCouldHaveBeenMoved = postURLName && board?.requestedBoardID;

    if (viewer.loggedOut) {
      // it'll redirect
      return null;
    } else if (isLoading) {
      return (
        <ContentContainer className="contentLoading">
          <Spinner />
        </ContentContainer>
      );
    } else if (company.notFound) {
      return (
        <ContentContainer outerClassName="notFound">
          There is no such company. Did you enter the right URL?
        </ContentContainer>
      );
    } else if (error) {
      return (
        <ContentContainer outerClassName="notFound">
          Something went wrong, please try again later.
        </ContentContainer>
      );
    } else if (!company.viewerIsMember) {
      return (
        <ContentContainer outerClassName="notAuthorized">
          You aren't a member of this company.
        </ContentContainer>
      );
    } else if (board && board.notFound && !postCouldHaveBeenMoved) {
      return (
        <ContentContainer outerClassName="notFound">
          There is no such board. Did you enter the right URL?
        </ContentContainer>
      );
    } else if (board && board.notAuthorized && !postCouldHaveBeenMoved) {
      return (
        <ContentContainer outerClassName="notAuthorized">
          This board is private, please use an account that can access it.
        </ContentContainer>
      );
    }

    const viewerIsLoggedIn = viewer && viewer._id;
    const BoardSettingsRegex = /^\/admin\/board\/[a-z0-9-]+\/settings/i;
    const isViewingSettings =
      location.pathname.startsWith('/admin/settings') ||
      location.pathname.match(BoardSettingsRegex);

    if (viewerIsLoggedIn && !isViewingSettings) {
      const { billingData, limits, planTrial, stats } = company;
      const planExpired = !billingData?.plan;
      if (planExpired) {
        const onboardingIncomplete = billingData?.status === 'expired';
        return (
          <AdminPlanExpired onboardingIncomplete={onboardingIncomplete} planTrial={planTrial} />
        );
      }

      const planTrialExpired = !planTrial?.cancelled && planTrial?.expired && !planTrial?.upgraded;
      if (planTrialExpired) {
        return <AdminPlanExpired planTrial={planTrial} />;
      }

      const { activePosts } = limits;
      const { activePostCount, activePostLimitLockoutAt } = stats;
      const postLimitExceeded = activePosts && activePosts < activePostCount;
      const postLimitLockedOut =
        activePostLimitLockoutAt && new Date(activePostLimitLockoutAt) < new Date();
      if (postLimitExceeded && postLimitLockedOut) {
        return <AdminPlanExpired activePostLimitLockout />;
      }
    }

    return this.props.children;
  }

  renderAnnouncements() {
    const { company, location, router } = this.props;

    if (!company?.nux) {
      return null;
    }

    const isNUXEnabled = !company.nux.autopilot;
    const isInAutopilotSettings = location.pathname.startsWith('/admin/settings/autopilot');
    const isCompanyRecentlyCreated = dayjs().subtract(1, 'day').isBefore(company.created);

    if (!isNUXEnabled || isInAutopilotSettings || isCompanyRecentlyCreated) {
      return null;
    }

    return (
      <CannyAnnouncementModal
        body={`Never let valuable feedback slip through the cracks again with Canny Autopilot. Automatically detect and consolidate feedback from all your sources in one place.\nConnect a data source to get started.`}
        cta="Enable Autopilot"
        image={AutopilotAnnouncement}
        learnMore="https://help.canny.io/en/articles/8202451-beta-inbox"
        name="autopilot"
        onSuccess={() => router.replace('/admin/settings/autopilot')}
        title="Feedback Discovery on Autopilot"
      />
    );
  }

  renderGrowthTrialCountdown() {
    const {
      company: { trialingPlan, viewerIsMember },
    } = this.props;
    if (!viewerIsMember || !trialingPlan) {
      return null;
    }

    return <AdminV2TrialNotice plan={trialingPlan} />;
  }

  renderTrialCountdown() {
    const {
      company: { billingData, viewerIsMember },
    } = this.props;
    if (!viewerIsMember) {
      return null;
    }

    const { plan } = billingData;
    const trialing = plan && plan.planID === '101';
    if (!trialing) {
      return null;
    }

    const trialEndMilliseconds = new Date(billingData.trialEnds).getTime();
    const nowMilliseconds = new Date().getTime();

    const dayMilliseconds = 1000 * 60 * 60 * 24;
    const millisecondsTilTrialEnds = trialEndMilliseconds - nowMilliseconds;
    const daysTilTrialEnds = Math.round(millisecondsTilTrialEnds / dayMilliseconds);
    const dayOrDays = daysTilTrialEnds === 1 ? 'day' : 'days';

    return (
      <div className="trialCountdown">
        <span className="callTo">
          {daysTilTrialEnds} {dayOrDays} left On your trial
        </span>
        <div className="middot">&middot;</div>
        <span className="action">
          <Link to="/admin/settings/billing">Subscribe Now</Link>
        </span>
      </div>
    );
  }

  renderPostLimit() {
    const { limits, stats, viewerIsMember } = this.props.company;
    if (!viewerIsMember) {
      return null;
    }

    return <AdminV2PostLimitNotice limits={limits} stats={stats} />;
  }

  isFullScreenPath() {
    const {
      location: { pathname },
    } = this.props;

    if (
      pathname.startsWith('/admin/changelog') ||
      pathname.startsWith('/admin/feedback') ||
      pathname.startsWith('/admin/autopilot') ||
      pathname.startsWith('/admin/moderation') ||
      pathname.startsWith('/admin/notifications') ||
      pathname.startsWith('/admin/roadmap') ||
      pathname.startsWith('/admin/settings') ||
      pathname.startsWith('/admin/users')
    ) {
      return true;
    }

    return false;
  }

  render() {
    const {
      viewer: { loggedOut },
      company,
    } = this.props;
    if (loggedOut) {
      return null;
    }

    const isIDBEnabled = company?.featureAllowlist?.includes('idea-database');
    const className = classnames('adminContainer', {
      fixedHeight: this.isFullScreenPath(),
    });

    return (
      <IsAdminViewContext.Provider value={true}>
        <Helmet allowIndexing={false} />
        <PostLinkContainer getPostLink={this.getPostLink}>
          <TintColorContainer tintColor={DefaultTintColor}>
            <BackgroundColorContainer backgroundColor={Colors.white}>
              <ToastContainer>
                <ModalContainer>
                  <CannyContainer>
                    <HubSpotContainer>
                      <IntercomContainer>
                        <LinkedInContainer>
                          <MixpanelContainer property="admin" trackPageViews={true}>
                            <CannylyticsContainer>
                              <BingContainer>
                                <EventContainer>
                                  <StripeContainer>
                                    <AccessContainer>
                                      <div className={className}>
                                        <div className="adminContentsContainer">
                                          {this.renderTrialCountdown()}
                                          {this.renderGrowthTrialCountdown()}
                                          {this.renderAnnouncements()}
                                          {this.renderPostLimit()}
                                          <div
                                            className={classnames('adminContents', {
                                              v2: isIDBEnabled,
                                            })}>
                                            <BoardsContainer>
                                              <BoardContainer>
                                                <PostContainer>
                                                  <ChangelogEntryContainer>
                                                    <AutopilotCountsContainer>
                                                      {isIDBEnabled ? (
                                                        <AdminV2Nav
                                                          notifications={this.props.notifications}
                                                        />
                                                      ) : (
                                                        <AdminNav
                                                          notifications={this.props.notifications}
                                                        />
                                                      )}
                                                    </AutopilotCountsContainer>
                                                  </ChangelogEntryContainer>
                                                </PostContainer>
                                              </BoardContainer>
                                            </BoardsContainer>
                                            {this.renderContents()}
                                          </div>
                                        </div>
                                      </div>
                                    </AccessContainer>
                                  </StripeContainer>
                                </EventContainer>
                              </BingContainer>
                            </CannylyticsContainer>
                          </MixpanelContainer>
                        </LinkedInContainer>
                      </IntercomContainer>
                    </HubSpotContainer>
                  </CannyContainer>
                </ModalContainer>
              </ToastContainer>
            </BackgroundColorContainer>
          </TintColorContainer>
        </PostLinkContainer>
      </IsAdminViewContext.Provider>
    );
  }
}

export default compose(
  asyncConnect([BoardsContainer.asyncConnect, boardAsyncFetch], (state) => ({
    boards: state.boards,
  })),
  withContexts(
    {
      company: CompanyContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminContainer);
